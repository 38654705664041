<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout pb-2 wrap justify-center>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Edit Category</span>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
              <v-layout pt-4 wrap justify-space-around>
                <v-flex xs12 sm12 md4>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        ref="name"
                        v-model="name"
                        prepend-icon="mdi-earth"
                        label="Category Name"
                        color="#26af82"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout wrap justify-center>
                <v-flex xs12 sm12 md4 text-center>
                  <v-btn
                    @click="updateCategory"
                    rounded
                    small
                    dark
                    :ripple="false"
                    class="text-capitalize"
                    outlined
                    color="#26af82"
                    width="250"
                  >Update Category</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      name: null
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/project/category/get",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          this.name = response.data.data.name;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    },
    updateCategory() {
      this.appLoading = true;
      var data = {};
      data["name"] = this.name;
      data["id"] = this.id;
      axios({
        method: "POST",
        url: "/project/category/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$router.push("/Categories");
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
</style>